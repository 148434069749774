.ReimbursementGuidelines__main-title{
    color:  var(--standard-color);
    text-align: center;
    font-family: 'Fugaz One', sans-serif;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ReimbursementGuidelines__section-description{
    margin-top: 2em;
    color: #000;
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;

}
.ReimbursementGuidelines__main-section-container{
    padding: 5em;
}
.ReimbursementGuidelines__customer-service{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
}



@media (max-width: 575.98px) {
    .ReimbursementGuidelines__main-section-container{
        padding: 2em;
    }
    .ReimbursementGuidelines__main-title{
        margin-top: 1.5em;
        font-size: 3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.3rem;
    }
}
        
        
@media (min-width: 576px) and (max-width: 767.98px) {
    .ReimbursementGuidelines__main-section-container{
        padding: 2em;
    }
    .ReimbursementGuidelines__main-title{
        margin-top: 1.5em;
        font-size: 3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.3rem;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .ReimbursementGuidelines__main-section-container{
        padding: 2em;
    }
    .ReimbursementGuidelines__main-title{
        margin-top: 1.5em;
        font-size: 3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.3rem;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .ReimbursementGuidelines__main-title{
        margin-top: 1.5em;
        font-size: 3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.3rem;
    }

}

@media (min-width: 1200px) {
    .ReimbursementGuidelines__customer-service-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 3em;
    }
    .ReimbursementGuidelines__customer-service{
        width: 50%;
    }
}