.Footer__footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color)
    /* border: 3px solid red; */
}

.Footer__main-footer-container{
    /* border: 3px solid rgb(0, 0, 0); */
    display: flex;
    flex-direction: row; 
    justify-content: center;   
    align-items: center;
    width: 55%;
    /* border: 8px solid red; */
}
.MainContainer__right-separator{
    border-right: 3px solid rgb(0, 0, 0);
    height: 10em;
}
    .Footer__left-container{
        /* border: 10px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 30%;
        padding: .3em;
        margin-left: 2em;

        
    }
        
        .Footer__icons{
            width: 1.5em;
            height: 1.5em;
            margin-right: .5em;
            margin-bottom: .3em;
            /* border: 2px solid red; */
        }
        .Footer__left-logo-container{
           /* border: 3px solid blue;  */
           margin-bottom: .6em;
           width: 10em;
           height: 4em;
        }
            .Footer__logo{
                width: 100%;
                
            }
        .Footer__left-contact-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            /* border: 2px solid red; */
        }
            .Footer__left-contact-container p{
                color: #B20918;
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 600;
                line-height: 0.875rem; 
                text-transform: uppercase;
                /* border: 3px solid blue; */
                margin-bottom: 0;
                
            }
        .Footer__left-email-container{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /* border: 2px solid blue; */
        }
            .Footer__left-email-container p{
                color: #B20918;
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 600;
                line-height: 0.875rem; 
                text-transform: lowercase;
                margin-bottom: 0;
            }
    
    .Footer__center-container{
        /* border: 3px solid blue; */
        width: 35%;
    }

            .Footer__center-nav ul{
                list-style-type: none;
            }
                .Footer__center-nav ul li a:hover{
                    border-bottom: 3px solid var(--tertiary-color);
                }
                .Footer__center-nav ul li a{
                    text-decoration: none;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.05rem; 
                    text-transform: uppercase;
                }
    

    
    .Footer__right-container{
        /* border: 3px solid red; */
        width: 35%;
    }

            .Footer__right-nav ul {
                /* border: 2px solid green; */
                list-style-type: none;
            
            }
                .Footer__right-nav ul li a:hover{
                    border-bottom: 3px solid var(--tertiary-color);
                    
                }
                .Footer__right-nav ul li a {
                    text-decoration: none;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.05rem; 
                    text-transform: uppercase;
                }
                   
                   

.Footer__main-legal-description-container{
    /* border: 3px solid purple; */
    width: 45%;
}
    .Footer__legal-description-container{
        /* border: 3px solid black; */
        display: flex;
        flex-direction: column;
        padding-top: .5em;
        padding-left: 1.5em;
        margin-top: 1.5em;
        margin-left: 4em;

    }
        .Footer__legal-description-container{
            color: #00030A;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.60688rem; 
        }

    .Footer__right-and-cards-icon-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-left: 5.5em;

    }   
        .Footer__copy-right-container{
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            justify-items: center;
        }   
            .Footer__copy-right-container p {
                /* border: 2px solid white; */
                margin-top: 1em;
            }
    .Footer__card-icon-container{
        /* border : 3px solid black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
        .Footer__credit-card-icons{

        } 
        
        .Footer__business-address{
            font-size: .8rem;
            font-family: 'Lato', sans-serif;
            font-weight: 600;
        }

/*==================== RESPONSIVE =========================*/

@media (max-width: 470.98px){
 
    .Footer__center-nav ul li {
     
    
        padding-left: .4em !important;
        padding-right: .4em !important;
     /*    border: 3px solid white; */
       
    }
}
@media (max-width: 575.98px) {
.Footer__footer{
        /* border: 4px solid black; */
        display: flex;
        flex-direction: column;
        padding-top:1.5em;
        padding-left: 1em;
        padding-right: .4em;
        
    }
        .Footer__main-footer-container{
            width: 100%;
            /* border: 2px solid black; */
            flex-direction: column;
           
        }
            .Footer__center-container{
                width: 100%;
                /* border: 2px solid blue; */

               
            }
            .Footer__left-container{
                width: auto;
                /* border: 2px solid red; */
            }
            .Footer__right-container{
                /* border: 2px solid green; */
                width: 100%;
            }
        .MainContainer__right-separator{
            /* border: 3px solid green; */
            height: 0;
            width: 0;
            display: none;
        }
        .Footer__main-legal-description-container{
            /* border: 3px solid blue; */
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin:0;
            padding: 0;
        }
            .Footer__legal-description-container{
                /* border: 2px solid green; */
                margin:0;
                padding: .5em;
            }
            .Footer__right-and-cards-icon-container{
                /* border: 2px solid red; */
                margin:0;
                padding: .3em;
                flex-direction: column;
                align-items: center;

            }

            .Footer__center-container ul li{
                text-align: center;
            }
            .Footer__right-container ul li{
                text-align: center;
                /* border: 3px solid white; */
            }
            .Footer__center-container ul{
                 padding: .5em;
                 display: flex;
                 flex-direction: row;
                 justify-content: space-between;
            }
            .Footer__right-container ul{
                padding: .5em;
                display: flex;
                flex-direction: row; 
                justify-content: space-between;
            
            }
  }

@media (min-width: 576px) {
    .Footer__footer{
        /* border: 2px solid black; */
        display: flex;
        flex-direction: column;
        padding-top:1.5em;
        padding-left: 1em;
        padding-right: .4em;
        
    }
        .Footer__main-footer-container{
            width: 100%;
        }
            .Footer__left-container{
                width: auto;
            }
        .MainContainer__right-separator{
            /* border: 3px solid green; */
            height: 0;
            width: 0;
            display: none;
        }
        .Footer__main-legal-description-container{
            /* border: 3px solid blue; */
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin:0;
            padding: 0;
        }
            .Footer__legal-description-container{
                /* border: 2px solid green; */
                margin:0;
                padding: .5em;
            }
            .Footer__right-and-cards-icon-container{
                /* border: 2px solid red; */
                margin:0;
                padding: .3em;

            }
            .Footer__center-container ul li{
                text-align: center;
            }
            .Footer__right-container ul li{
                text-align: center;
            }
}


@media (min-width: 768px) { 
    .Footer__main-legal-description-container{
        flex-direction: row;
        justify-content: center;
        margin:0;   
        padding: 0;
    }
    .Footer__right-and-cards-icon-container{
        /* border: 2px solid red; */
        margin:0;
        padding: .3em;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

        .Footer__copy-right-container p {
            font-size: .9rem;
        }

        .Footer__center-container ul li{
            text-align: center;
        }

        .Footer__right-container ul li{
            text-align: center;
        }
}


@media (min-width: 992px) {
    .Footer__main-footer-container{
        /* border: 3px solid white; */
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
        .Footer__main-legal-description-container{
            /* border: 3px solid blue; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin:0;
            padding: 0;
        }
            .Footer__legal-description-container{
                /* border: 2px solid green; */
                margin:0;
                padding: .5em;
            }
            .Footer__right-and-cards-icon-container{
                /* border: 2px solid red; */
                margin:0;
                padding: .3em;

            }
                .Footer__center-container ul li{
                    text-align: center;
                }

                .Footer__right-container ul li{
                    text-align: center;
                }
}


@media (min-width: 1200px) {
     
    .Footer__footer{
        flex-direction: row;
        /* border: 3px solid green; */
    }

    .Footer__main-footer-container{
        /* border: 3px solid red; */
    }

    .Footer__main-legal-description-container{
        /* border: 3px solid blue; */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin:0;
        padding: 0;
    }
    .Footer__right-and-cards-icon-container{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .Footer__legal-description-container p {
        /* border: 6px solid green; */
        margin-left: 3em;
        font-size: 1rem;
    }
 
.Footer__center-container,.Footer__right-container{
    margin-left: 2em;
    margin-top: 1.5em;
}

    .Footer__right-container ul li{
        text-align: left;
    }
    .Footer__center-container ul li{
        text-align: left;
    }
    .Footer__legal-description-container{
        padding-top: 2em;
    }
    
}


@media (min-width: 1400px) { 


}



/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .AssociatedBrands__main-container{
     /* border: 3px solid red; */
    }
     .AssociatedBrand__main-title-container{
         /* border: 2px solid blue; */
     }
     .AssociatedBrand__icons-contianer{
         /* border: 2px solid green; */
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
     }
     .AssociatedBrand__brand-one, .AssociatedBrand__brand-two,
     .AssociatedBrand__brand-three, .AssociatedBrand__brand-four,
     .AssociatedBrand__brand-five{
         /* border: 2px solid red; */
         width: 8em;
         height: auto;
     }
         .AssociatedBrand__icon{
             width: 100%;
             height: auto;
         }
  }
