
.PaymentInfo__title{
    color: var(--standard-color);
    text-align: center;
    font-family: 'Fugaz One', sans-serif;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.PaymentInfo__payment-form-container{
    /* border: 3px solid red; */
    margin-bottom: 2em;
}



@media (max-width: 575.98px) {
    .PaymentInfo__main-section{
        /* border: 9px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .PaymentInfo__main-title-container{
        /* border: 6px solid red; */
    }
        .PaymentInfo__title{
            /* border: 3px solid yellow; */
            font-size:3rem;
            line-height: 2.7rem;

        }
        .PaymentInfo__payment-form-container{
            width: 100%;
        }
 
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .PaymentInfo__title{
        /* border: 3px solid yellow; */
        font-size:3rem;
        line-height: 3rem;
    }
    .PaymentInfo__payment-form-container{
        /* border: 3px solid blue; */
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
}


@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {
}