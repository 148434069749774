.PaymentForm__main-section-container{
    display: flex;
    flex-direction: row;
    /* border: 2px solid blue; */
    padding-left: 2em;
    /* padding-right: 2em; */
    padding-bottom: 0;
    
}
    .PaymentForm__payment-container{
        /* border: 3px solid red; */
        width: 100%;
        /* margin-left: 4em; */
        padding-left: 1.6em;
        padding-right: 1.6em;
        margin-top: 1.8em;
    }
    .PaymentForm__subscription-card-container{
        /* border: 3px solid green ; */
        width: 100%;
        /* margin-right: 4em; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

.PaymentForm__billing-address-container{
    /* margin-bottom: 2.5em; */
    /* border: 3px solid red; */
    /* margin-left: 2em; */
   

}
    .PaymentForm__billing{
        /* margin-left: 6em;
        margin-right: 6em; */
        /* border: 3px solid green; */
        padding-left: .6em;
        padding-right: .6em;
    }

.PaymentForm__orders-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-content: center;
}
    .PaymentForm__order{
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid gray;
    }
        .PaymentForm__order span{
            color: #000;
            font-family: Lato;
            font-size: 1rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    .PaymentForm__total{
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }
        .PaymentForm__total{
            color: #000;
            font-family: Lato;
            font-size: 1rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
.PaymentForm__terms-container{
    margin-top: 1em;
    margin-bottom: 1em;
}
.PaymentForm__lbl-terms{
    color: #000;
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
  
}
.PaymentForm__price{
    font-weight: 600;
}
.PaymentForm__orders-container{
    margin-top: 1em;
}




@media (max-width: 575.98px) {
    .PaymentForm__main-section-container{
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        /* border: 6px solid rgb(230, 25, 25); */
        width: 100%;
    }
    .PaymentForm__terms-container{
        margin-left: .5em;
    }
        .PaymentForm__payment-container{
            order: 2;
            /* border: 6px solid rgb(83, 128, 0); */
            margin-left: 2em;
            margin-right: 2em;
        
        }
        .PaymentForm__subscription-card-container{
            /* border: 6px solid blue; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    .PaymentForm__billing-address-container{
        /* border : 3px solid red */
    }
        .PaymentForm__billing{
            /* border: 3px solid green; */
        }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .PaymentForm__main-section-container{
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        /* border: 6px solid rgb(230, 25, 25); */
        width: 100%;
    }
    .PaymentForm__subscription-card-container{
            /* border: 6px solid blue; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .PaymentForm__payment-container{
            order: 2;
            /* border: 6px solid rgb(83, 128, 0); */
                /* margin-left: 2em;
                margin-right: 2em; */
        
        }
    .PaymentForm__terms-container{
        /* border: 3px solid red; */
        margin-left: .9em;
    }
    .PaymentForm__payment-container{
        /* border: 3px solid black; */
    }
    .PaymentForm__subscription-card-container{
        /* border: 3px solid blue; */
    }
    .PaymentForm__main-section-container{
        padding: 0;
        margin: 0;
      }

}


@media (min-width: 768px) and (max-width: 991.98px) {
    .PaymentForm__main-section-container{
        padding: 0;
        margin: 0;
      }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
   .PaymentForm__main-section-container{
     padding: 0;
     margin: 0;
   }


}

@media (min-width: 1200px) {
    .PaymentForm__billing-address-container{
        margin-left: 2em;
    }
}