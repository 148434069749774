
.SectionInfo__main-section-container{
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 3em;
    /* border: 3px solid green; */
}
    .SectionInfo__title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #E66931;
        
        /* padding: 2rem 2rem; */
        /* border: 3px solid red; */
    }
        .SectionInfo__title{
            color: #FFF;
            font-family: 'Roboto Flex';
            font-size: 3rem;   
            font-style: normal;
            font-weight: 600;
            line-height: 1.05rem;
        }


@media (max-width: 575.98px) {
    .SectionInfo__main-section-container{
        margin: 0;
        padding: 0;
    }
    .SectionInfo__title{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem;
    }
    .SectionInfo__title-container{
        padding: .5em .5em .1em .1em;
    }
    
}


@media (min-width: 576px) and (max-width: 767.98px) {
   
    .SectionInfo__main-section-container{
        padding: 0;  
    }
   
    .SectionInfo__title{
        line-height: 3.3rem;
        margin: .5em .5em .5em 1em;
        text-align: center;
        font-size: 2.5rem;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionInfo__title{
        line-height: 3rem;
    }
    .SectionInfo__main-section-container{
        padding: 0;  
    }
    .SectionInfo__title{
        line-height: 3.3rem;
        margin: .5em .5em .5em 1em;
        text-align: center;
        font-size: 2.5rem;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SectionInfo__title{
        line-height: 3.5rem;
    }

    .SectionInfo__main-section-container{
        padding: 0;  
    }
    .SectionInfo__title{
        line-height: 3.3rem;
        margin: .5em .5em .5em 1em;
        text-align: center;
        font-size: 2.5rem;
    }

}

@media (min-width: 1200px) {
    .SectionInfo__title{
        line-height: 2.5rem;
    }
    .SectionInfo__main-section-container{
        padding: 0;  
    }
    .SectionInfo__title{
        line-height: 3.3rem;
        margin: .5em .5em .5em 1em;
        text-align: center;
        font-size: 2.5rem;
    }
}
        