.SubscriptionDescription__main-container{
    /* border: 3px solid red; */
}
    .SubscriptionDescription__inner-img-container{
        /* border: 3px solid blue; */
    }
        .SubscriptionDescription__img{
            /* border: 2px solid green; */
        }                 

    .SubscriptionDescription__title-container{
        /* border: 3px solid blue */
    }
    .SubscriptionDescription__title-container p{
        color:  var(--quinary-color);
        font-family: 'Fugaz One', sans-serif;
        font-size: 3.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: .6em;
    }
    .SubscriptionDescription__description-container{
        /* border: 3px solid red; */
    }
    .SubscriptionDescription__description-container p{
        color: #00030A;
        font-family: 'Lato', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem; 
    }



@media (max-width: 575.98px) {
    .SubscriptionDescription__inner-img-container{
        /* border: 3px solid blue; */
        width: auto;
    }
        .SubscriptionDescription__img{
            /* border: 2px solid green; */
            width: 100%;
        }
        .SubscriptionDescription__title-container p{
            font-size: 2.5rem;
        }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionDescription__inner-img-container{
        /* border: 3px solid blue; */
        width: auto;
    }
        .SubscriptionDescription__img{
            /* border: 2px solid green; */
            width: 100%;
        }
        .SubscriptionDescription__title-container p{
            font-size: 3.3rem;
        }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionDescription__inner-img-container{
        /* border: 3px solid blue; */
        width: auto;
    }
        .SubscriptionDescription__img{
            /* border: 2px solid green; */
            width: 100%;
        }
        .SubscriptionDescription__title-container p{
            font-size: 3rem;
        }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionDescription__inner-img-container{
        /* border: 3px solid blue; */
        width: auto;
    }
        .SubscriptionDescription__img{
            /* border: 2px solid green; */
            width: 100%;
        }
        .SubscriptionDescription__title-container p{
            font-size: 3rem;
            font-weight: 600;
        }

}

@media (min-width: 1200px) {
    .SubscriptionDescription__main-container{
        padding: 5em;
    }
    .SubscriptionDescription__inner-img-container{
        /* border: 3px solid blue; */
        width: auto;
    }
        .SubscriptionDescription__img{
            /* border: 2px solid green; */
            width: 100%;
        }
        .SubscriptionDescription__title-container p{
            font-weight: 600;
        }
}