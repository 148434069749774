.Membership__main-title{
    color:  var(--standard-color);
    text-align: center;
    font-family: 'Fugaz One', sans-serif;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-wrap: break-word;
    margin-bottom: 1em;

}
.Membership__title{
    text-decoration: underline;
}
.Membership__section-description{
    color: #00030A;
    font-family: 'Lato', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem; 
}
.Membership__main-section-container{
    padding: 5em;
}

.MembershipTerms__customer-service{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3em;
}

@media (max-width: 575.98px) {
    .Membership__main-section-container{
        padding: 2em;
    }
}
        
        
@media (min-width: 576px) and (max-width: 767.98px) {
    .Membership__main-section-container{
        padding: 2em;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
}


@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {
    .MembershipTerms__customer-service-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 3em;
    }
    .MembershipTerms__customer-service{
        width: 50%;
    }
    .Membership__main-section-container{
        padding: 5em;
    }
}